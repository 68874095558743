<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="bg-welcome-proplat">
          <div class="content-fruits-image">
            <div class="fruits-image">
              <img
                src="@/assets/images/fruits-welcome.png"
                alt=""
              >
            </div>
          </div>
          <p class="txt-title mon-bold">{{ texts.home.banner.welcome }}</p>
          <p
            class="txt-description mon-medium"
            v-html="texts.home.banner.description"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeBannerLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
    };
  },
  computed: {
    sFullName: function () {
      var text = "";
      text = this.$store.state.sFullName.split(" ")[0];
      return text;
    },
  },
};
</script>

<style scoped>
.bg-welcome-proplat {
  background: url("../../assets/images/bg-banner-welcome.png");
  width: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
}

.fruits-image {
  position: absolute;
  width: 600px;
  right: -20px;
  top: 0px;
}

.fruits-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .fruits-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.txt-title {
  color: #283c4d;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 35px;
}

.txt-description {
  color: #283c4d;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
}

@media (max-width: 960px) {
  .fruits-image {
    position: absolute;
    width: 350px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .bg-welcome-proplat {
    padding-top: 90px;
  }
  .txt-description {
    text-align: center;
  }
  .txt-title {
    font-size: 25px;
    text-align: center;
  }
  .bg-welcome-proplat {
    background: url("../../assets/images/bg-banner-welcome-responsive.png");
  }
}
</style>